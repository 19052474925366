import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Popover, PopoverHeader, PopoverBody, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { BrowserView, MobileView } from 'react-device-detect';

import Close from 'images/close.inline.svg';
import Info from 'images/information.inline.svg';

const StyledHelper = styled.div`
 
`;

const Label = styled.div`
  display: inline-block;
  ${props => props.theme.main.fonts.application.micro};
  color: ${props => props.theme.main.colors.secondary.dark1};
  cursor: pointer;
  margin-bottom: 16px;
  svg {
    margin-top: -2px;
    path {
      fill: ${props => props.theme.main.colors.secondary.dark1};
    }
  }
`;

const StyledPopover = styled(Popover)`
  .popover {
    border:none;
    border-radius: 0;
    box-shadow: 0 2px 4px 0 rgba(119,110,100,0.2);
    border-left: 1px solid ${props => props.theme.main.colors.secondary.base};

    .popover-header {
      ${props => props.theme.main.fonts.desktop.h5};
      background-color: white;
      border-bottom: none;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding: 13px 22px;
      padding-bottom: 10px;

      svg {
        cursor: pointer;
      }
    }
    .popover-body {
      ${props => props.theme.main.fonts.body.normal};
      padding: 20px 22px;
      padding-top: 0;
    }
    .arrow {
      &:before {
        border-left-color: rgba(0,0,0,.2);
      }
    }
  }
`;

const StyledModal = styled(Modal)`
  .modal-content {
    border:none;
    border-radius: 0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    border-top: 2px solid ${props => props.theme.main.colors.secondary.base};

    .modal-header {
      .modal-title {
        ${props => props.theme.main.fonts.application.h3};
      }
      border-bottom: none;
      padding: 16px;
      padding-bottom: 8px;
    }
    .modal-body {
      ${props => props.theme.main.fonts.body.normal};
      padding: 16px;
      padding-top: 0;
    }
  }

`;

const Helper = ({children, header, label, name}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  }

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  }

  const keyDown = (e) => {    
    if(e.key === 'Enter') {      
      toggle();
    }
  }

  return (
    <StyledHelper>
      <BrowserView>
        <Label id={name} tabIndex="0" onKeyDown={keyDown}><Info /> {label}</Label>
        <StyledPopover placement="left-start" isOpen={isOpen} target={name} toggle={toggle}>
          <PopoverHeader>{header} <Close onClick={toggle} /></PopoverHeader>
          <PopoverBody>{children}</PopoverBody>
        </StyledPopover>
      </BrowserView>
      <MobileView>
        <Label onClick={toggleModal}><Info /> {label}</Label>
        <StyledModal isOpen={modalOpen} toggle={toggleModal} centered={true}>
          <ModalHeader toggle={toggleModal}>{header}</ModalHeader>
          <ModalBody>
            {children}
          </ModalBody>
        </StyledModal>
      </MobileView>
    </StyledHelper>);
}

Helper.propTypes = {
  header: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.node
}

export default Helper;