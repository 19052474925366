import React from 'react';
import { navigate } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { saveLoanCalculator } from 'actions/actions';

import Form from 'components/Common/Form/Form';
import Box from 'components/Common/Layout/Box';
import Header from 'components/Common/Layout/Header';
import Navigation from 'components/Common/Layout/Navigation';
import Calculator from 'components/Common/Calculator/Calculator';
import Disclaimer from 'components/Common/Layout/Disclaimer';

export default React.memo(() => {
  const dispatch = useDispatch();
  const { loanType } = useSelector(state => state.reducer);

  const tempLoanCalculator = useSelector(state => state.reducer.tempLoanCalculator);

  const submit = (e) => {
    e.preventDefault();
    if(canContinue) {
    dispatch(saveLoanCalculator());
      navigate('requirements', { replace: true });
    }
  }

  const canContinue = (() => {    
    return (tempLoanCalculator.paymentAmount);
  })();

  return (
    <Form>
      <Header>{loanType === 'OnlineFairAndFastLoan' ? <>Calculate your Vancity Fair & Fast loan<sup>&trade;</sup></> : 'Calculate your Personal Loan'}</Header>
      <Box>
        <Calculator displayFootnotes={true} />
      </Box>
      <Navigation buttonLabel="Apply" onClickForward={submit} disabled={!canContinue} />
      <Disclaimer>
        <p id="footnote-1"><sup>1</sup> The annual percentage rate (APR) is compounded monthly, not in advance. APR assumes no fees or charges apply. If additional fees and/or charges apply, the total Cost of Credit and APR would increase. This product, its features and advertised interest rates may change at any time and may be withdrawn by Vancity at any time for any reason without prior notice to the member.</p>
      </Disclaimer>
    </Form>);
});