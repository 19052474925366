import React from 'react';
import styled  from 'styled-components';
import { Row, Col } from 'reactstrap';
import breakpoint from 'styled-components-breakpoint';

import Footnote from 'components/Common/Layout/Footnote';

const Interest = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  border-bottom: 2px solid ${props => props.theme.main.colors.neutral.line};
  margin-right: 16px;
  margin-left: 16px;
  .heading {
    ${props => props.theme.main.fonts.body.bold};
    color: ${props => props.theme.main.colors.grey.base};
  }
  .amount {
    ${props => props.theme.main.fonts.application.h2};
    span {
      font-size: 16px;
    }
  }

  ${breakpoint('md')` 
    border-right: 2px solid ${props => props.theme.main.colors.neutral.line};
    border-bottom: none;
    margin-right: 0;
    margin-left: 0;
  `}
`;

const Cost = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  padding-bottom: 0;
  .heading {
    ${props => props.theme.main.fonts.body.bold};
    color: ${props => props.theme.main.colors.grey.base};
  }
  .amount {
    ${props => props.theme.main.fonts.application.h2};
    span {
      font-size: 16px;
    }
  }
  ${breakpoint('md')` 
    padding-bottom: 16px;
  `}

`;

const MonthlyPayment = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-top: 2px solid ${props => props.theme.main.colors.secondary.base};
  border-bottom: 2px solid ${props => props.theme.main.colors.neutral.line};
  padding: 32px;
  padding-bottom: 8px;
  .heading {
    ${props => props.theme.main.fonts.body.bold};
    color: ${props => props.theme.main.colors.secondary.dark1};
  }
  .amount {
    color: ${props => props.theme.main.colors.secondary.dark1};
    ${props => props.theme.main.fonts.application.shoutout};
    font-size: 56px;
    line-height: 67px;
    span {
      font-size: 28px;
    }
  }
`;

export default ({paymentAmount, interestRate, totalCostOfCredit, paymentsTotal, paymentFrequency, displayFootnotes = false}) => {
  let paymentFrequencyLabel = '';
  if(paymentFrequency === 'Monthly') {
    paymentFrequencyLabel = 'monthly';
  }
  else if(paymentFrequency === 'BiWeekly') {
    paymentFrequencyLabel = 'bi-weekly';
  }
  else if(paymentFrequency === 'Weekly') {
    paymentFrequencyLabel = 'weekly';
  }

  return (
    <div aria-live="polite">
      <Row>
        <Col>
          <MonthlyPayment>
            <div className="heading">Your <b>{paymentFrequencyLabel}</b> payment</div>
            <div className="amount" data-testid="monthlyPayment">{paymentAmount !== null ? <div>${paymentAmount.toFixed(2)}</div> : '$ -'}</div>
          </MonthlyPayment>
        </Col>
      </Row>
      <Row>
        <Interest md={6}>
          <div className="heading">Interest <span data-testid="interestRate">{interestRate ? `${interestRate}%`  : '-'}</span> {displayFootnotes ? <Footnote to="footnote-1">1</Footnote> : null}</div>
          <div className="amount" data-testid="totalCostOfCredit">{totalCostOfCredit !== null ? <div>${totalCostOfCredit.toFixed(2)}</div> : '$ -'}</div>
        </Interest>
        <Cost md={6}>
          <div className="heading">Loan + interest</div>
          <div className="amount" data-testid="paymentsTotal">{paymentsTotal !== null ? <div>${paymentsTotal.toFixed(2)}</div> : '$ -'}</div>
        </Cost>
      </Row>
    </div>);
}