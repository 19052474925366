import React from 'react'

import Layout from 'components/Layout/Layout';
import LoanCalculator from 'components/LoanCalculator/LoanCalculator';
import SEO from 'components/seo';

const IndexPage = () => (
  <Layout>
    <SEO title="Loan Calculator" />
    <LoanCalculator />
  </Layout>
)

export default IndexPage
