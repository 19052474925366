import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Range, getTrackBackground  } from 'react-range';

import Arrow from 'images/arrow.inline.svg';

const StyledSlider = styled.div`
  margin: 0px 20px;
  margin-top: 10px;
`;

const OuterTrack = styled.div`
  height: 36px;
  display: flex;
  width: 100%;
  &:before {
    width: 5px;
    height: 5px;
    content: ' ';
    background-color: ${props => props.theme.main.colors.primary.base};
    border-radius: 50%;
    align-self: center;
  }
  &:after {
    width: 5px;
    height: 5px;
    content: ' ';
    background-color: #EEECEA;
    border-radius: 50%;
    align-self: center;
  }
`;

const InnerTrack = styled.div`
  height: 2px;
  width: 100%;
  align-self: center;
`;

const Thumb = styled.div`
  height: 32px;
  width: 32px;
  background-color: ${props => props.theme.main.colors.primary.base};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LeftArrow = styled(Arrow)`
  transform: rotate(90deg);
  margin: 2px;
`;

const RightArrow = styled(Arrow)`
  transform: rotate(-90deg);
  margin: 2px;
`;

const Steps = styled.ul`
  ${props => props.theme.main.fonts.application.micro};
  color: ${props => props.theme.main.colors.grey.base};
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
  margin: 0 -10px;
  margin-top: 5px;

  li {
    width: ${props => props.stepWidth}%;
    text-align: center;
    &:first-of-type {
      text-align: left;
    }
    &:last-of-type {
      text-align: right;
    }
  }
`;

const Slider = ({name, min, max, steps = [], value, onChange, step = 1}) => {
  const [state, setState] = useState({
    values: [min]
  });

  useEffect(() => {
    setState({values: [value < min ? min : (value > max ? max : value)]});
  }, [value]);
  
  const sliderChange = (values) => {
    setState({values});
    onChange({target: {name: name, value: `${values[0]}`}});
  }

  return (
    <StyledSlider>
      <Range
        step={step}
        min={min}
        max={max}
        values={state.values}
        onChange={sliderChange}
        renderTrack={({ props, children }) => (
          <OuterTrack
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{...props.style}}>
            <InnerTrack
              {...props}
              style={{
                ...props.style,
                background: getTrackBackground({
                  values: state.values,
                  colors: ['#CA1905', '#EEECEA'],
                  min: min,
                  max: max
                })
              }}>
              {children}
            </InnerTrack>
          </OuterTrack>
        )}
        renderThumb={({ props }) => (
          <Thumb {...props} style={{...props.style}} aria-labelledby={name} data-testid={`slider-${name}`}>
            <LeftArrow />
            <RightArrow />
          </Thumb>
        )} />
        {steps.length > 0 ?
          <Steps stepWidth={(100 / steps.length)}>
            {steps.map((step, i) => <li key={i}>{step}</li>)}
          </Steps> : null }
    </StyledSlider>
  );
}

Slider.propTypes = {
  name: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  steps: PropTypes.array,
  value: PropTypes.number,
  onChange: PropTypes.func,
  step: PropTypes.number
}


export default Slider;