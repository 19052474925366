import React, { useState, useEffect } from 'react';
import styled  from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { updateLoanCalculation, updateLoan, resetCalculator, updateState } from 'actions/actions';
import { FormGroup } from 'reactstrap';
import { useDebouncedCallback } from 'use-debounce';
import { formatCurrency, formatLengthSteps } from 'utils/format';
import breakpoint from 'styled-components-breakpoint';

import Results from './Results';

import Input from 'components/Common/Form/Input';
import Label from 'components/Common/Form/Label';
import Slider from 'components/Common/Form/Slider';
import Radio from 'components/Common/Form/Radio';
import Helper from 'components/Common/Form/Helper';

const StyledFormGroup = styled(FormGroup)`
  margin-bottom: ${props => props.last ? 48 : 32}px;
`;

const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  .custom-radio {
    width: 100%;
    padding-bottom: 8px;
  }
  ${breakpoint('md')` 
    flex-direction: row;
    .custom-radio {
      padding-bottom: 0;
    }
  `}
`;

export default React.memo(({displayFootnotes = false}) => {
  const dispatch = useDispatch();
  const { applicationId, cifId, loanType, loanApproval, firstPaymentDate, accessToken, isMobile } = useSelector(state => state.reducer);

  const loan = loanType === 'OnlineFairAndFastLoan' ? {
    minAmount: loanApproval ? loanApproval.minAmount : 100,
    maxAmount: loanApproval ? loanApproval.maxAmount : 2500,
    amountSteps: loanApproval ? [formatCurrency(loanApproval.minAmount, 0), formatCurrency(loanApproval.maxAmount, 0)] : ['$100', '$2,500'],
    minLength: loanApproval ? (loanApproval.minLoanTermUnit === 1 ? 0 : loanApproval.minLoanTerm ) : 0,
    maxLength: loanApproval ? loanApproval.maxLoanTerm : 24,
    lengthStep: 1,
    lengthSteps: loanApproval ? formatLengthSteps(loanApproval.minLoanTerm, loanApproval.maxLoanTerm, loanApproval.minLoanTermUnit) : ['2 weeks', '2 years']
  } : {
    minAmount: loanApproval ? loanApproval.minAmount :100,
    maxAmount: loanApproval ? loanApproval.maxAmount : 10000,
    amountSteps: loanApproval ? [formatCurrency(loanApproval.minAmount, 0), formatCurrency(loanApproval.maxAmount, 0)] : ['$100', '$10,000'],
    minLength: loanApproval ? loanApproval.minLoanTerm : 6,
    maxLength: loanApproval ? loanApproval.maxLoanTerm : 60,
    lengthStep: 6,
    lengthSteps: loanApproval ? formatLengthSteps(loanApproval.minLoanTerm, loanApproval.maxLoanTerm, loanApproval.minLoanTermUnit) : ['6 months', '5 years']
  }

  const loanCalculator = useSelector(state => state.reducer.loanCalculator);
  const tempLoanCalculator = useSelector(state => state.reducer.tempLoanCalculator);
  const [state, setState] = useState({
    loanAmount: loanCalculator.loanAmount || loan.minAmount,
    loanAmountInput: loanCalculator.loanAmount || '',
    loanLength: loanCalculator.term ? (loanCalculator.termUnits === 'Weeks' ? '0' : loanCalculator.term) : loan.minLength.toString(),
    loanLengthInput: loanCalculator.term ? (loanCalculator.termUnits === 'Weeks' ? '0' : loanCalculator.term) : '',
    paymentFrequency: loanCalculator.paymentFrequency ||  '',
    loanPurpose: loanCalculator.loanPurpose || '',
    paymentAmount: loanCalculator.paymentAmount || null,
    interestRate: loanCalculator.interestRate || null,
    totalCostOfCredit: loanCalculator.totalCostOfCredit || null,
    paymentsTotal: loanCalculator.paymentsTotal || null
  });

  const [dispatchLoanCalc] = useDebouncedCallback(() => {       
    if(state.paymentFrequency !== '' && state.loanAmountInput !== '' && state.loanLengthInput !== '') {
      dispatch(updateLoanCalculation({
        cifId: cifId,
        applicationId: applicationId,
        calculation: {
          request: {
            loanAmount: state.loanAmountInput, 
            term: parseInt(state.loanLengthInput) === 0 ? 2 : state.loanLengthInput, 
            termUnits: parseInt(state.loanLengthInput) > 0 ? 'Months' : 'Weeks', 
            paymentFrequency: state.paymentFrequency, 
            loanType: loanType,
            firstPaymentDate: firstPaymentDate
          }
        }
      }, accessToken, isMobile));
    }
  }, 500);

  const handleChange = (event) => {
    let { name, value } = event.target;    
    if(name === 'loanAmount') {
      value = value.replace(/\D/g,'');
      if(value < loan.minAmount) {
        value = loan.minAmount;
      }
      else if(value > loan.maxAmount) {
        value = loan.maxAmount;
      }
      setState(prevState => ({ ...prevState, loanAmountInput: value }));
    }
    else if(name === 'loanPurpose') {
      dispatch(updateLoan({loanPurpose: value}));
    }
    else if(name === 'loanLength') {
      if(value === '0' && state.paymentFrequency === 'Monthly') {    
        setState(prevState => ({ ...prevState, paymentFrequency: '' }));
        dispatch(resetCalculator());
      }
      setState(prevState => ({ ...prevState, loanLengthInput: value }));
    }
    setState(prevState => ({ ...prevState, [name]: value }));
  }

  const changeLoanAmount = (event) => {
    let { name, value } = event.target;
    value = value.replace(/\D/g,'');
    setState(prevState => ({ ...prevState, loanAmountInput: value }));

    let loanAmount = value;
    if(value < loan.minAmount) {
      loanAmount = loan.minAmount;
    }
    else if(value > loan.maxAmount) {
      loanAmount = loan.maxAmount;
    }
    dispatch(updateState({tempLoanCalculator: {
      ...tempLoanCalculator,
      loanAmount: loanAmount,
      loanAmountChanged: true
    }}))
  }

  useEffect(() => {    
    dispatchLoanCalc();
  }, [state.loanAmount, state.loanLength, state.paymentFrequency]);

  useEffect(()=> {
    setState(prevState => ({ ...prevState, 
      paymentAmount: tempLoanCalculator.paymentAmount,
      interestRate: tempLoanCalculator.interestRate,
      totalCostOfCredit: tempLoanCalculator.totalCostOfCredit,
      paymentsTotal: tempLoanCalculator.paymentsTotal,
      loanPurpose: prevState.loanPurpose }));      
  }, [tempLoanCalculator.paymentAmount, tempLoanCalculator.interestRate, tempLoanCalculator.totalCostOfCredit, tempLoanCalculator.paymentsTotal]);

  return (
    <div>
        <StyledFormGroup>
          <Label name="loanAmount">Loan Amount</Label>
          <Input type="text" name="loanAmount" onChange={changeLoanAmount} onBlur={handleChange} value={state.loanAmountInput !== '' ? formatCurrency(state.loanAmountInput, 0) : ''} />       
          <Slider name="loanAmount" step={10} min={loan.minAmount} max={loan.maxAmount} steps={loan.amountSteps} value={parseInt(state.loanAmount)} onChange={handleChange} />
        </StyledFormGroup>
        <StyledFormGroup>
          <Label name="loanLength">Loan Length</Label>      
          <Helper name="loanLengthHelper" label="What is loan length?" header="Loan Length">
            Loan length is the amount of time you can take to pay back your loan. <br /><br />
            A shorter loan length will increase the amount of each payment, but you will pay less interest over time. <br /><br />
            A longer loan length will decrease the amount of each payment, but will add more interest. <br /><br />
            Whatever you choose, you can pay back your loan earlier.
          </Helper>                 
          <Input type="select" name="loanLength" onChange={handleChange} value={`${state.loanLengthInput}`}>
            <option value='' disabled>Select</option>
            {loanType === 'OnlineFairAndFastLoan' ?
              Array((loan.maxLength + 1) / loan.lengthStep).fill().map((e,i) => {
                  const optionText = (i === 0) ? '2 weeks' : (i % 12 === 0 ? i / 12 + ' year' + (i / 12 > 1 ? 's' : '') : i + ' month' + (i > 1 ? 's' : ''));
                  return (<option value={i} key={i}>{optionText}</option>);
              })
            : Array(loan.maxLength / loan.lengthStep).fill().map((e, i) => {
                const index = (i + 1) * 6;
                const optionText = (index / 12 >= 1 ? `${Math.floor(index/12)} year${Math.floor(index/12) > 1 ? 's ' : ' '}` : '') + (index % 12 !== 0 ? `${index % 12} months` : '');
                return (<option value={index} key={i}>{optionText}</option>);
              })
            } 
          </Input>
          <Slider name="loanLength" step={loan.lengthStep} min={loan.minLength} max={loan.maxLength} steps={loan.lengthSteps} value={parseInt(state.loanLength)} onChange={handleChange} />
        </StyledFormGroup>          
        <StyledFormGroup>
          <Label name="paymentFrequency">Payment Frequency</Label>
          <Helper name="paymentFrequencyHelper" label="What is payment frequency?" header="Payments">
            Payment frequency is how often payments are withdrawn from your account.
            <br /><br />
            Bi-weekly is every 14 days.
          </Helper>     
          <RadioContainer>
            <Radio name="paymentFrequency" value="Monthly" label="Monthly" inline={true} onChange={handleChange} selectedValue={state.paymentFrequency} disabled={(state.loanLength === '0')} />
            <Radio name="paymentFrequency" value="BiWeekly" label="Bi-weekly" inline={true} onChange={handleChange} selectedValue={state.paymentFrequency} />
            <Radio name="paymentFrequency" value="Weekly" label="Weekly" inline={true} onChange={handleChange} selectedValue={state.paymentFrequency} />
          </RadioContainer>     
        </StyledFormGroup>
        <Results paymentAmount={state.paymentAmount} interestRate={state.interestRate} totalCostOfCredit={state.totalCostOfCredit} paymentsTotal={state.paymentsTotal} paymentFrequency={state.paymentFrequency} displayFootnotes={displayFootnotes} />
    </div>);
});